var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "m-5"
  }, [_c('b-row', [_c('b-col', [_c('b-table', {
    ref: "refParticipantsListTable",
    staticClass: "mb-0 h-100",
    attrs: {
      "fields": _vm.standingsTableCols,
      "items": _vm.standings,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.isSortDirDesc,
      "empty-text": "No standings yet",
      "primary-key": "id",
      "show-empty": "",
      "table-class": "h-100"
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(name)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.participantName(data.item)) + " ")];
      }
    }, {
      key: "cell(rank)",
      fn: function fn(data) {
        return [data.index === 0 ? [_c('b-img', {
          attrs: {
            "src": require("@/assets/images/icons/1stplace.png"),
            "alt": "1st place",
            "height": "20"
          }
        })] : data.index === 1 ? [_c('b-img', {
          attrs: {
            "src": require("@/assets/images/icons/2place.png"),
            "alt": "2nd place",
            "height": "20"
          }
        })] : data.index === 2 && data.item.elapsed_time !== null ? [_c('b-img', {
          attrs: {
            "src": require("@/assets/images/icons/3place.png"),
            "alt": "3rd place",
            "height": "20"
          }
        })] : [_vm._v(" " + _vm._s(data.index + 1) + " ")]];
      }
    }, {
      key: "cell(elapsed_time)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.elapsed_time ? _vm.millisecondsToTime(data.item.elapsed_time) : 'no result yet') + " ")];
      }
    }, {
      key: "cell(difference)",
      fn: function fn(data) {
        return [data.index === 0 ? [_vm._v(" " + _vm._s('--:--:--.--') + " ")] : [_vm._v(" " + _vm._s(data.item.difference ? _vm.millisecondsToTime(data.item.difference) : '') + " ")]];
      }
    }, {
      key: "cell(gap)",
      fn: function fn(data) {
        return [data.index === 0 ? [_vm._v(" " + _vm._s('--:--:--.--') + " ")] : [_vm._v(" " + _vm._s(data.item.gap ? _vm.millisecondsToTime(data.item.gap) : '') + " ")]];
      }
    }, {
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('b-spinner', {
          staticClass: "align-middle mr-2"
        }), _c('strong', [_vm._v("Loading...")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }